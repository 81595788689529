import { createRoot } from "react-dom/client";

import { waitForElementWithId } from "@utils/dom";

import JobScroll from "./components/JobScroll";

waitForElementWithId("jobScroll").then((el) => {
  const root = createRoot(el);

  root.render(<JobScroll />);
});
