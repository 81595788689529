import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import LocationMarker from "../../../../onboarding/assets/img/location.svg";
import BankOfAmericaLogo from "../../../../web/templates/web/icons/bankofamerica.svg";
import BloombergLogo from "../../../../web/templates/web/icons/bloomberg.svg";
import JohnsonLogo from "../../../../web/templates/web/icons/johnson_johnson_logo.svg";
import PandGLogo from "../../../../web/templates/web/icons/pandg.svg";
import SAPLogo from "../../../../web/templates/web/icons/sap.svg";

type JobCardProps = {
  logo: string;
  title: string;
  employer: string;
  location: string;
};

const jobCards: JobCardProps[] = [
  {
    title: "HR Specialist Germany - Werkstudent 2025",
    employer: "Johnson & Johnson",
    location: "Berlin",
    logo: JohnsonLogo,
  },
  {
    title: "Analytics & Insights Manager – Graduate Entry",
    employer: "P&G",
    location: "Remote",
    logo: PandGLogo,
  },
  {
    title: "2025 Analytics & Sales - German Speakers",
    employer: "Bloomberg",
    location: "Berlin",
    logo: BloombergLogo,
  },
  {
    title: "Business Processes Associate Consultant",
    employer: "SAP",
    location: "München",
    logo: SAPLogo,
  },
  {
    title: "Global Payment Solutions Summer Analyst",
    employer: "Bank of America",
    location: "Frankfurt",
    logo: BankOfAmericaLogo,
  },
];

const animationVariants = {
  hiddenTop: { y: -232, opacity: 0, scale: 0 },
  previous: { y: -116, x: 5, opacity: 0.5, scale: 0.9 },
  current: { y: 0, opacity: 1, scale: 1 },
  next: { y: 116, opacity: 0.5, scale: 0.9 },
  hiddenBottom: { y: 180, opacity: 0, scale: 0.5 },
};

const JobCard = ({ logo, title, employer, location }: JobCardProps) => {
  return (
    <div className="tw-flex !tw-w-full tw-flex-row tw-items-center tw-space-x-4 tw-rounded-regular tw-bg-white tw-p-[20px] tw-shadow-regular">
      <div>
        <img
          className="!tw-h-[70px] !tw-w-[70px]"
          src={logo}
          alt={`${employer} Logo`}
        />
      </div>
      <div>
        <p className="!tw-mb-2 tw-text-sm tw-font-bold tw-leading-[22px] tw-text-primary">
          {title}
        </p>
        <div className="tw-flex tw-flex-row tw-space-x-2 tw-text-sm tw-font-medium">
          <p className="!tw-mb-0">{employer}</p>
          <div className="tw-flex tw-flex-row tw-items-center tw-space-x-1">
            <img
              className="!tw-max-w-[15px]"
              src={LocationMarker}
              alt={window.gettext("Location icon")}
            />
            <p className="!tw-mb-0">{location}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const JobScroll = () => {
  const [activeJobCardIndex, setActiveJobCardIndex] = useState(0);

  useEffect(() => {
    const duration = 1750;
    const interval = setInterval(() => {
      setActiveJobCardIndex(
        (previousIndex) => (previousIndex + 1) % jobCards.length,
      );
    }, duration);
    return () => clearInterval(interval);
  }, []);

  const getCurrentAnimationVariant = (jobCardIndex: number) => {
    let position = jobCardIndex - activeJobCardIndex;

    if (position < -Math.floor(jobCards.length / 2)) {
      position += jobCards.length;
    }

    if (position > Math.floor(jobCards.length / 2)) {
      position -= jobCards.length;
    }

    switch (position) {
      case 0:
        return "current"; // this is the active card
      case 1:
        return "next"; // this is the card below the active card (the next card to be active)
      case -1:
        return "previous"; // this is the card above the active card (the previous card to be active)
      default:
        return position < 0 ? "hiddenTop" : "hiddenBottom";
    }
  };

  return (
    <div className="tw-relative tw-w-full">
      <div className="!tw-absolute tw-top-0 tw-z-[200] tw-h-[150px] !tw-w-full tw-bg-gradient-to-b tw-from-lime-15 tw-via-lime-15 tw-to-transparent lg:tw-h-[200px]"></div>
      <div className="!tw-absolute !tw-bottom-0 tw-z-[200] tw-h-[100px] !tw-w-full tw-bg-gradient-to-t tw-from-lime-15 tw-to-transparent"></div>
      <div className="tw-relative tw-z-[50] tw-m-auto tw-flex tw-h-[380px] !tw-w-[100%] tw-flex-col tw-items-center sm:!tw-w-[75%] xl:!tw-w-[100%]">
        {jobCards.map((jobCard, jobCardIndex) => {
          const currentAnimationVariant =
            getCurrentAnimationVariant(jobCardIndex);

          return (
            <motion.div
              key={jobCard.title}
              variants={animationVariants}
              className="tw-absolute tw-bottom-[35%] !tw-w-full sm:!tw-w-[410px] lg:tw-bottom-auto lg:tw-top-[50%]"
              animate={currentAnimationVariant}
              initial={false}
              transition={{ duration: 0.6 }} // this should match Figma
            >
              <JobCard
                location={jobCard.location}
                logo={jobCard.logo}
                title={jobCard.title}
                employer={jobCard.employer}
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default JobScroll;
